/* *{ cursor: inherit;

}*/

body {
  /* background-color: #251711; color: white; margin: 0px; */
  cursor: none;
  /* overflow: hidden; */
  user-select: none;
  -webkit-user-select: none;
  /* align-content: center; */
  justify-content: center;
}

.gallery {
  margin: 0px;
}

.cursor {
  position: fixed;
  z-index: 10000;
  border: 0px;
  pointer-events: none;
  height: 50px;
}
/* 
.vignette{
    position: absolute; padding: 0px;
}

.vignetteMouseOver canvas {
    -webkit-filter: grayscale(0)!important;
    filter: grayscale(0)!important;
}

.vignetteMouseOver .titre {
    background-color: rgba(220,0,0,1)!important;
}

.vignette canvas {
    background-color: lime; position: absolute; z-index: 5;
    -webkit-transition : all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}

.vignette .titre {
    color: #ddd; width: 100%; background-color: rgba(255,25,0,0.5);
    font-size: 18pt; position: absolute; z-index: 10;
    font-family: 'Poiret One', cursive; letter-spacing: 3px;
    text-indent: 12px;
    padding-top: 8px;
    padding-bottom: 8px; font-weight: bold;
    pointer-events: none;
     -webkit-transition : all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.vignette .paris {
    font-size: 104pt; letter-spacing: 10px; margin-left: -10px; margin-top: 24px;
    font-family: 'Poiret One', cursive; color: #ccc
}

.vignette .explanation {
    line-height: 1.5em;
    font-family: 'Poiret One'; font-size: 14pt;
}

.content {
    display: none; position: absolute;
    opacity: 1;
    z-index: 20;
    width: 800px; height: 600px;
    top: 50%; left: 50%;
    margin-top: -300px; margin-left: -400px;
    background-color: rgba(0,0,0,0.9);
    overflow: hidden;
}

.content .contentTitle {
    font-family: 'Poiret One', cursive; letter-spacing: 3px;
    font-size: 44pt;
    color: #ff1900;
    margin: 10px;
}

.content .contentInside {
    font-size: 14pt; line-height: 1.5em;
     font-family: 'Poiret One', cursive;
    margin: 60px;
    text-align: justify;
}

 #headControlsCanvas {
    position: fixed;
    right: 0px;
    bottom: 0px;
    z-index: 1000;
    transform-origin: bottom right;
    max-height: 25%;
    max-width: 25%;
  } */
#App {
  font-family: sans-serif;
  text-align: center;
}

p {
  padding-right: 16%;
  padding-left: 16%;
  width: 67%;
  text-align: center;
}
h3 {
  padding-top: 5%;
  padding-bottom: 7%;
  padding-right: 16%;
  padding-left: 16%;
  width: 67%;
  text-align: center;
}
img {
  padding-bottom: 10%;
}
h2,
h4 {
  text-align: center;
}

h1 {
  position: fixed;
  width: 100vw;
  top: 0%;
}
.SaladName {
  padding-top: 16%;
}
* {
  cursor: crosshair;
}

#scrollTopX {
  position: fixed;
  top: 10vh;
  left: 0;
  width: 100vw;
  height: 10vh;
  /* padding-top: 10vh; */
  background-color: green;
  opacity: 0.5;
}
#scrollTopOne {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 10vh;
  background-color: red;
  opacity: 0.5;
}

#scrollBottomX {
  position: fixed;
  bottom: 10vh;
  left: 0;
  width: 100vw;
  height: 10vh;
  background-color: green;
  opacity: 0.5;
}
#scrollBottomOne {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 10vh;
  background-color: red;
  opacity: 0.5;
}

button.scrollAssistant:focus {
  background-color: yellow;
}
button.scrollAssistant:hover {
  background-color: blue;
}
button.scrollAssistant {
  width: 100vw;

  z-index: 2;
  height: 10vh;
  background-color: gray;
  opacity: 0.5;
}
#headControlsCanvas {
  position: fixed;
  width: 30vw;
  height: auto;
  z-index: 10;
  top: 0;
  right: 0;
}

body {
  background: #fff;
  z-index: 0;
}

section:nth-child(even) {
  border: 2px solid #00000088;
  border-radius: 32px;
  background: #ab8ae788;
}
section:nth-child(odd) {
  border: 2px solid #00000088;
  border-radius: 32px;
  background: wheat;
}
section h3 {
  width: auto;
  height: 50%;
  padding: 0;
}
section {
  width: 100%;
  height: 40vh;
  font-size: 32px;
  display: flex;
  flex-direction: column;
}
button.message {
  position: relative;
  align-self: center;
  z-index: 1;

  width: 80%;
  height: 100%;

  padding: 64px 64px;

  font-size: 3vmin;

  border-radius: 20px;
  margin: 32px;
  background: gray;
}
textarea {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
}
div#rootContainer {
  height: auto;
  padding-top: 40vh;
  padding-bottom: 40vh;
}
